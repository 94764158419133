import { createReducers } from '@redthreads/redux-helpers'
import constants, { dimensions, questions, headerFields as header } from 'store/constants'

const form = Object.keys(questions).reduce((outernObj, question) => {
  const keys = Object.keys(dimensions).reduce((innerObj, dimension) => {
    return {
      ...innerObj,
      [`${dimension}-${question}`]: '',
    }
  }, {})

  return {
    ...outernObj,
    ...keys,
  }
}, {})

const headerFields = Object.keys(header).reduce((acc, key) => {
  return { ...acc, [key]: '' }
}, {})

const initialState = {
  content: {
    ...headerFields,
    ...form,
  },
  cloud: false,
}

const reducer = createReducers({
  [constants.CLOUD]: (state) => ({
    ...state,
    cloud: true,
  }),
  [constants.SYNC]: (state, payload) => ({
    ...state,
    ...payload,
    cloud: true,
  }),
  [constants.EDIT]: (state, content) => ({
    ...state,
    content,
  }),
})

const reducers = (state = initialState, action) => reducer(state, action)
export default reducers
