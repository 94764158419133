import React, { useState, useCallback } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

const Wrapper = styled.small`
  color: gray;
  font-size: 11;
  text-align: right;
  display: block;
`

const debounce = (func, delay) => {
  let timerId

  return (...args) => {
    if (!timerId) {
      func(...args)
    }
    clearTimeout(timerId)

    timerId = setTimeout(() => func(...args), delay)
  }
}

const AutoSave = ({ debounceMs, cloud }) => {
  const formik = useFormikContext()
  const [lastSaved, setLastSaved] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(
      () => formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    debouncedSubmit()
  }, [debouncedSubmit, formik.values])

  let label = 'enable cloud sync to save'
  if (cloud) {
    label = lastSaved !== null ? `Last Saved: ${lastSaved}` : 'not saved'
  }

  return <Wrapper>{label}</Wrapper>
}

export default AutoSave
