import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  white-space: pre;
`

export const Input = styled.input`
  font-size: 1.5em;
  height: 40px;
  border: 1px solid #ccc;
  flex-shrink: 1;
  width: 100%;
`

export const TextArea = styled.textarea`
  width: 100%;
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #ccc;
`
