import { keys } from '@redthreads/redux-helpers'

export const dimensions = {
  Knowledge: `Knowledge are the things you know. 
    Knowledge is different from skills in that 
    it consists of the facts, information, or 
    theoretical understanding of subjects.`,
  Attitude: `Attitude is one of the most important factors
    because it's what keeps you going. It isn’t necessarily
    about having a <i>good</i> or <i>bad</i> attitude, but how your 
    awareness of your own outlook and state-of-mind
    on a given task influences your performance.`,
  Skills: `Skills are your ability to perform tasks. An individual 
    acquires their skillsets through training, experience 
    or natural abilities. Skills are different from knowledge 
    because knowledge is a prerequisite to skills.`,
  Habits: `Habits consist of the tiny individual choices and behaviors
    we act out and repeatedly do with little to no effort.`,
}

export const questions = {
  Goal: 'What does the role require?',
  Reality: "What's the situation today?",
  Options: 'What are the options to bridge the gap?',
  Actions: 'What will we do?',
}

export const headerFields = {
  name: 'Your Name',
  role: 'Current Role',
  ambition: 'Career Goal',
}

export default keys(['EDIT', 'SYNC', 'CLOUD'])
