import firebase from 'firebase/app'
import 'firebase/database'
import { validate, v4 } from 'uuid'

const {
  REACT_APP_API_KEY: apiKey,
  REACT_APP_DB_URL: databaseURL,
  REACT_APP_ENV: env,
} = process.env

firebase.initializeApp({
  apiKey,
  databaseURL,
})

const db = firebase.database()

let id = document.location.pathname.substring(1)

if (!validate(id)) {
  id = v4()
}

const fromDb = (prj, type, dispatch) => {
  db.ref(`/${prj}/${env}/${id}`).on('value', (data) => {
    if (data.val()) {
      dispatch({
        type,
        payload: data.val(),
      })
    }
  })
}

const toDb = (prj, { cloud, ...data }) => {
  if (cloud) {
    window.history.replaceState(null, document.title, `/${id}`)

    db.ref(`/${prj}/${env}/${id}`).set({ ...data })
  }
}

export const cloudSync = (prj, event, store, transform) => {
  store.subscribe(() => toDb(prj, transform(store.getState())))
  fromDb(prj, event, store.dispatch)
}
