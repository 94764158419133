import styled from 'styled-components'
import React from 'react'

const FooterUi = styled.footer`
  padding: 10px;
  font-size: 12px;
`

const Footer = () => (
  <FooterUi>
    made by{' '}
    <a href="https://cedmax.com" target="_blank" rel="noopener noreferrer">
      cedmax
    </a>
  </FooterUi>
)

export default Footer
