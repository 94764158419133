import React from 'react'
import styled from 'styled-components'
import { Input, Label } from '../Input'
import { Cloud } from '@redthreads/components'
import { headerFields } from 'store/constants'

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: auto;
  position: relative;
  padding-bottom: 100px;
`

export const CloudEnableWrapper = styled.div`
  position: absolute;
  bottom: 20px;
`

export const Block = styled.div`
  padding: 0 0 12px 25px;
  flex: 1 1 auto;
  box-sizing: border-box;
`

const KashHeader = ({ values, onChange, enableCloud, cloud }) => (
  <>
    <Header>
      {Object.entries(headerFields).map(([field, label]) => (
        <Block key={field}>
          <Label>{label}</Label>{' '}
          <Input name={field} value={values[field]} onChange={onChange} />
        </Block>
      ))}

      <CloudEnableWrapper>
        <Cloud vertical cloud={cloud} onCloudEnabled={enableCloud} />
      </CloudEnableWrapper>
    </Header>
  </>
)

export default KashHeader
