import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <title>Enable Cloud Sync</title>
      <g fill="none" fillRule="evenodd">
        <circle cx="64" cy="64" r="64" fill="#ccc"></circle>
        <path
          fill="black"
          d="M49.498 91.962a1.5 1.5 0 01-1.299-2.25l30-51.96a1.495 1.495 0 012.05-.549 1.5 1.5 0 01.552 2.05l-30 51.96c-.28.48-.784.75-1.303.75m-5.112-8.879c.243-.423.267-.94.06-1.38a1.499 1.499 0 00-1.095-.846c-6.402-1.146-7.35-7.215-7.35-8.814 0-4.182 2.853-7.773 6.942-8.733l1.488-.351a1.51 1.51 0 00.94-.684 1.52 1.52 0 00.17-1.146l-.378-1.482c-.11-.438-.162-.786-.162-1.104 0-2.481 2.016-4.5 4.5-4.5.846 0 1.65.24 2.394.71l1.506.955c.372.24.834.3 1.254.165.423-.132.765-.447.933-.855l.684-1.647a13.44 13.44 0 019.31-7.926c.395-.096.737-.348.938-.702l.018-.03a1.49 1.49 0 00-.093-1.626 1.487 1.487 0 00-1.533-.558 16.467 16.467 0 00-11.409 9.693 7.428 7.428 0 00-4.002-1.18 7.499 7.499 0 00-7.5 7.5c0 .643.105 1.252.255 1.846-5.3 1.245-9.255 5.976-9.255 11.655 0 5.916 4.293 10.779 9.927 11.76.57.099 1.14-.165 1.425-.666l.033-.054zM85.07 60.738c.103-.738.172-1.488.172-2.256 0-3-.807-5.808-2.208-8.23a1.393 1.393 0 00-2.41 0l-.11.193a1.485 1.485 0 00-.006 1.47 13.345 13.345 0 011.734 6.567 13.4 13.4 0 01-.144 1.848l-.162 1.203a1.497 1.497 0 00.972 1.614l1.14.41C87.608 64.852 90 68.236 90 71.983c0 4.962-4.038 9-9 9H63.687c-.495 0-.957.264-1.203.696l-.033.054a1.505 1.505 0 000 1.5c.267.465.762.75 1.299.75H81c6.627 0 12-5.373 12-12 0-5.19-3.315-9.57-7.93-11.244"
        ></path>
      </g>
    </svg>
  )
}

export default Icon
