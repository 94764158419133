import React from 'react'
import useCopy from '@react-hook/copy'
import CloudIconDisabled from './icons/CloudDisabled'
import CloudIconEnabled from './icons/CloudEnabled'
import { UnstyledButton, Box, Group } from '@mantine/core'

const Cloud = ({ cloud, onCloudEnabled, vertical }) => {
  const { copied, copy, reset } = useCopy(document.location.href)
  return (
    <UnstyledButton
      p={5}
      sx={{ verticalAlign: 'top' }}
      onMouseOut={reset}
      onClick={!cloud ? onCloudEnabled : copy}
    >
      <Group spacing="5px" sx={{ flexDirection: !vertical ? 'row' : 'column' }}>
        <Box w={30} mx="auto" role="img" aria-label="Clound Sync">
          {!cloud ? <CloudIconDisabled /> : <CloudIconEnabled />}
        </Box>

        <span>
          {!cloud
            ? 'Enable cloud sync'
            : !copied
            ? 'Cloud sync enabled, click to copy link'
            : 'Link copied'}
        </span>
      </Group>
    </UnstyledButton>
  )
}

export default Cloud
