import { connect } from 'react-redux'
import { updateContent, enableCloud } from 'store/action-creators'
import { getFormData, getCloudActivation } from 'store/selectors'
import UiComponent from './Header'

const mapStateToProps = (state) => ({
  data: getFormData(state),
  cloud: getCloudActivation(state),
})

const mapDispatchToProps = {
  updateContent,
  enableCloud,
}

export default connect(mapStateToProps, mapDispatchToProps)(UiComponent)
