import React from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Popover, ActionIcon, Center, Text } from '@mantine/core'

const ToolTip = ({ description }) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover opened={opened} position="right" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon
          onMouseEnter={open}
          onMouseLeave={close}
          bg="rgba(0, 0, 0, 0.5)"
          size={17}
          display="inline-block"
          c="#fff"
          sx={{ borderRadius: 100, ':hover': { background: '#000' } }}
        >
          <Center fz="xs">?</Center>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown bg="rgb(0, 0, 0, .8)" c="white">
        <Text
          ta="left"
          size="xs"
          fw={500}
          dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }}
        />
      </Popover.Dropdown>
    </Popover>
  )
  // </Tooltip>
}

export default ToolTip
