import React, { memo } from 'react'
import { AppFrame } from '@redthreads/components'
import { Provider } from 'react-redux'
import { Table } from 'components'
import Form from 'components/Form'
import Header from 'components/Header'

import AutoSave from 'components/AutoSave'
import store from 'store'

const Description = memo(() => (
  <>
    <p>
      K.A.S.H. is a framework of goal setting based on identifying the differences
      <br />
      between the aspired role and the current situation in 4 different key areas.
    </p>
  </>
))

export default memo(() => (
  <Provider store={store}>
    <AppFrame title="K.A.S.H." description={<Description />}>
      <Form>
        {({ handleChange, values }) => (
          <div style={{ width: '90%', paddingBottom: '2.5rem' }}>
            <Header values={values} onChange={handleChange} />
            <Table values={values} onChange={handleChange} />

            <AutoSave debounceMs={300} />
          </div>
        )}
      </Form>
    </AppFrame>
  </Provider>
))
