import React, { useCallback } from 'react'
import RMEditor from 'react-medium-editor'

require('medium-editor/dist/css/medium-editor.css')
require('medium-editor/dist/css/themes/beagle.css')

const Editor = ({ value, name, onChange }) => {
  const handleChange = useCallback(
    (text) => {
      onChange({
        target: {
          name: name,
          value: text,
        },
      })
    },
    [name, onChange]
  )

  return (
    <RMEditor
      style={{
        width: '100%',
        maxWidth: '300px',
        height: '200px',
        fontSize: '1em',
        overflow: 'scroll',
        padding: '10px',
        resize: 'none',
        border: '1px solid #ccc',
      }}
      text={value}
      onChange={handleChange}
      options={{
        toolbar: { buttons: ['bold', 'italic', 'underline', 'anchor'] },
      }}
    />
  )
}

export default Editor
