import React, { memo } from 'react'
import Indent from './Indent'
import Title from './Title'
import Subtitle from './Subtitle'

export default memo(({ title, children }) => (
  <Indent>
    <Title>{title}</Title>
    <Subtitle>{children}</Subtitle>
  </Indent>
))
