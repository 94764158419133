import dpi from 'dot-prop-immutable'
export { cloudSync } from './firebase-adapter'

export const createReducers =
  (reducers = {}) =>
  (state, { type, payload } = {}) =>
    reducers[type] ? reducers[type](state, payload) : state

export const actionObject = (type, payload) => ({
  type,
  payload,
})

export const keys = (keysList) =>
  keysList.reduce((result, key) => dpi.set(result, key, key), {})

const setAll = (state, pathObj) =>
  Object.entries(pathObj).reduce(
    (newState, [path, value]) => dpi.set(newState, path, value),
    state
  )

const mergeAll = (state, pathObj) =>
  Object.entries(pathObj).reduce(
    (newState, [path, value]) => dpi.merge(newState, path, value),
    state
  )

export const stateHelper = {
  ...dpi.set(dpi, 'setAll', () => setAll),
  ...dpi.set(dpi, 'mergeAll', () => mergeAll),
}
