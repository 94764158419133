import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { ToolTip } from '@redthreads/components'
import { questions, dimensions } from 'store/constants'
import Editor from 'components/Editor'

export default function TableExample({ values, onChange }) {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            {Object.entries(dimensions).map(([dimension, v]) => (
              <Th key={dimension} style={{ padding: 25 }}>
                {dimension} <ToolTip description={v} />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(questions).map(([question, v]) => (
            <Tr
              key={question}
              style={{
                border: 'none',
              }}
            >
              <Td style={{ padding: 25 }}>
                <strong>{question}</strong> <ToolTip description={v} />
              </Td>
              {Object.keys(dimensions).map((dimension, v) => (
                <Td key={`${dimension}-${question}`} style={{ padding: 5, width: '25%' }}>
                  <Editor
                    value={values[`${dimension}-${question}`]}
                    name={`${dimension}-${question}`}
                    onChange={onChange}
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}
