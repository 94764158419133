import React from 'react'
import Layout, { Body } from './Layout'
import Header from './Header'
import Footer from './Footer'
import './index.css'

const AppFrame = ({ title, children, description }) => (
  <Layout>
    <Header title={title}>{description}</Header>
    <Body>{children}</Body>
    <Footer />
  </Layout>
)

export default AppFrame
