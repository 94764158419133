import { connect } from 'react-redux'
import { updateContent } from 'store/action-creators'
import { getFormData } from 'store/selectors'
import UiComponent from './Form'

const mapStateToProps = (state) => ({
  data: getFormData(state),
})

const mapDispatchToProps = {
  updateContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(UiComponent)
