import React, { memo } from 'react'
import { Formik } from 'formik'

const Form = memo(({ data, updateContent, children }) => (
  <Formik enableReinitialize onSubmit={updateContent} initialValues={data}>
    {children}
  </Formik>
))

export default Form
