import { createStore, compose } from 'redux'
import { cloudSync } from '@redthreads/redux-helpers'
import reducers from 'store/reducers'
import constants from 'store/constants'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, composeEnhancers())

cloudSync('kash', constants.SYNC, store, ({ data: { cloud, content } }) => ({
  cloud,
  content,
}))

export default store
